import { JLLMap } from 'jlld';
import { Position } from 'jlld/es/JLLMap/types';
import Controler from 'jlld/es/JLLMap/Controler';
import { useShallow } from 'zustand/react/shallow';
import Empty from '@/components/Empty';
import MapLegend from './MapLegend';
import BezierPath from '@/components/BezierPath';
import DimensionSwitch from './DimensionSwitch';
import DistrictAndMarketPolygon from './DistrictAndMarketPolygon';
import { NORMALSTYLE } from '@/constants';
import useAreaPolygonList from './useAreaPolygonList';
import useRelocateAnalysisStore, { setTopDimension, queryData } from '../../store';
import styles from './style.module.less';
import { RelocateTrailDimensionType } from '@/constants/enums';

const Content = () => {
  const { mapData, dimension, topFormQueryParam, loading, noAuthCity } = useRelocateAnalysisStore(
    useShallow((state) => ({
      mapData: state.mapData,
      dimension: state.dimension,
      topFormQueryParam: state.topFormQueryParam,
      loading: state.loading,
      noAuthCity: state.noAuthCity,
    }))
  );
  const areaPolygonList = useAreaPolygonList();

  const arrowConfig = {
    fillColor: '#01151D',
    strokeColor: '#fff',
  };

  const dimensionSwitch = (
    d: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut
  ) => {
    queryData({
      ...topFormQueryParam,
      dimension: d,
    });
    setTopDimension(d);
  };
  if ((!mapData?.curArea || noAuthCity) && !loading) {
    return (
      <>
        <DimensionSwitch
          currentDemension={dimension}
          onClick={(val: RelocateTrailDimensionType) => dimensionSwitch(val)}
        />
        <div className={styles.mapEmpty}>
          <Empty
            text={
              noAuthCity
                ? '我们正在紧锣密鼓的补充数据中，请耐心等待'
                : '当前筛选条件下没有相关的迁址记录'
            }
            useSolsticeStyle
            noPadding
          />
        </div>
      </>
    );
  }
  let currentCenter;
  const others: Position[] = [];
  areaPolygonList?.forEach((item) => {
    if (item.current) {
      currentCenter = item.center;
    } else if (!item.isEmpty) {
      others.push(item.center);
    }
  });

  return (
    <>
      <DimensionSwitch
        currentDemension={dimension}
        onClick={(val) => dimensionSwitch(val)}
      />
      <DistrictAndMarketPolygon pathList={areaPolygonList} />
      <Controler controlList={['zoom', 'fullScreen']} />
      <BezierPath
        center={currentCenter}
        others={others}
        arrowConfig={arrowConfig}
        reverse={dimension === RelocateTrailDimensionType.moveIn}
      />
      <MapLegend />
    </>
  );
};

const MapView = () => {
  const { mapCenter } = useRelocateAnalysisStore(
    useShallow((state) => ({
      mapCenter: state.mapCenter,
    }))
  );
  const initialZoom = 11.5;

  return (
    <div className={styles.mapView}>
      <JLLMap
        animateEnable={false}
        zoom={initialZoom}
        zooms={[initialZoom - 2, 20]}
        mapStyle={NORMALSTYLE}
        center={mapCenter}
        // dragEnable={!!trailMapData?.curProperty}
      >
        <Content />
      </JLLMap>
    </div>
  );
};

export default MapView;
