import classNames from 'classnames';
import styles from '../style.module.less';

export default () => {
  return (
    <div className={styles.mapLegend}>
      <div className={styles.legendWrap}>
        <div className={styles.legend}>
          <div className={classNames(styles.legendIcon, styles.current)} />
          <div className={styles.curProperty}>当前项目</div>
        </div>
        <div className={styles.legend}>
          <div className={classNames(styles.legendIcon, styles.source)} />
          <div className={styles.curProperty}>来源区域</div>
        </div>
        <div className={styles.legend}>
          <div className={classNames(styles.legendIcon, styles.noData)} />
          <div className={styles.curProperty}>无数据</div>
        </div>
        <p>*数字为租户数量</p>
      </div>
    </div>
  );
};
