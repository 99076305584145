import { useCity } from '@/store/reactShareSelectedStore';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import styles from './style.module.less';
import { thousandSplitNumber } from '@/utils/tool';
import { JLLSvg } from 'jlld';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';

const CityInfoCard = () => {
  const { summary, submarket, submarketId, scaleToSubMarket, formData } = useIndustrialStore(
    (state) => ({
      summary: state.summary,
      submarket: state.submarket,
      submarketId: state.submarketId,
      scaleToSubMarket: state.scaleToSubMarket,
      formData: state.formData,
    })
  );
  const { data } = useMainFrameContext();
  const city = useCity();

  const gotoSubmarketDetail = () => {
    localStorage.setItem('queryTime', formData.queryTime);
    data.router.push(`/industrial-analysis/commercial-detail/${submarketId}`);
  };

  return (
    <div className={styles.cityBasicInfo}>
      <div className={styles.basicSummaryInfo}>
        <p className={styles.citySubmarket}>
          <span>{city}</span>
          {scaleToSubMarket ? <span>&nbsp;·&nbsp;{submarket}</span> : null}
        </p>
        <p className={styles.companyProject}>
          {formData.leaseType === '3' ? '退租企业' : '入驻企业'}
          <span className={styles.highlight}>
            {summary?.enterpriseCount ? thousandSplitNumber(summary?.enterpriseCount) : '--'}
          </span>
          个，项目
          <span className={styles.highlight}>
            {summary?.propertyCount ? thousandSplitNumber(summary?.propertyCount) : '--'}
          </span>
          个
        </p>
      </div>
      {submarketId && scaleToSubMarket ? (
        <div
          className={styles.submarketDetail}
          onClick={gotoSubmarketDetail}
        >
          <span>子市场详情</span>
          <JLLSvg
            icon="right-arrow"
            size={17}
            color="#667579"
          />
        </div>
      ) : null}
    </div>
  );
};

export default CityInfoCard;
