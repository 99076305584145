import { jllRequest } from '@/utils/request';
import {
  AnalysisItem,
  IndustrialPropertyItem,
  QueryIndustrialFormParams,
  Summary,
  QueryRelocateAnalysisMapParams,
  QueryRelocateAnalysisChartParams,
  QueryRelocateAnalysisMapResult,
  QueryRelocateAnalysisChartResult,
} from '../types/apiTypes';

// 行业竞争力分析
export function queryIndustryCompetitivenessAnalysis(data: QueryIndustrialFormParams) {
  return jllRequest<AnalysisItem[]>({
    data,
    url: '/industry/industryCompetitivenessAnalysis',
    method: 'POST',
  });
}
// 获取项目列表（地图上的点）
export function queryPropertyList(data: QueryIndustrialFormParams) {
  return jllRequest<IndustrialPropertyItem[]>({
    data,
    url: '/industry/propertyList',
    method: 'POST',
  });
}
// 热力图
// export function queryCityIndustryInfo(data: QueryIndustrialFormParams) {
//   return jllRequest<QueryIndustryListRes>({
//     data,
//     url: '/industry/queryCityIndustryInfo',
//     method: 'POST',
//   });
// }

// 汇总及分组信息（右边卡边上的概览）
export function querySummaryAndGrouping(data: QueryIndustrialFormParams) {
  return jllRequest<Summary>({
    data,
    url: '/industry/summaryAndGrouping',
    method: 'POST',
  });
}

//【新接口】汇总及分组信息&行业竞争力分析
export function querySummaryAndAnalysis(data: QueryIndustrialFormParams) {
  return jllRequest<Summary>({
    data,
    url: '/industry/industryAnalysis',
    method: 'POST',
  });
}

// 搬迁分析-地图数据接口
export function getRelocateAnalysisMapData(data: QueryRelocateAnalysisMapParams) {
  return jllRequest<QueryRelocateAnalysisMapResult>({
    url: '/migrationInAndOutAnalysis/map',
    method: 'POST',
    data,
  });
}

// 搬迁分析-地图图表接口
export function getRelocateAnalysisChartData(data: QueryRelocateAnalysisChartParams) {
  return jllRequest<QueryRelocateAnalysisChartResult>({
    url: '/migrationInAndOutAnalysis/chart',
    method: 'POST',
    data,
  });
}

// 搬迁分析-可选的城市列表
export function getCityList() {
  return jllRequest<QueryRelocateAnalysisMapResult>({
    url: '/migrationInAndOutAnalysis/cityList',
    method: 'POST',
  });
}
