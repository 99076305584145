import useMapStore from '@/store/mapStore';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import { useShallow } from 'zustand/react/shallow';
import { useMemo } from 'react';

const useAreaPolygonList = () => {
  const { districtMapDataList, submarketMapDataList } = useMapStore((state) => ({
    districtMapDataList: state.districtMapDataList,
    submarketMapDataList: state.subMarketMapDataList,
  }));
  const { boundariesType, formData, summary } = useIndustrialStore(
    useShallow((state) => ({
      boundariesType: state.boundariesType,
      formData: state.formData,
      summary: state.summary,
    }))
  );
  return useMemo(() => {
    let areaPolygonList;
    if (boundariesType === 'district') {
      areaPolygonList = districtMapDataList
        .filter((district) =>
          formData.districtAndSubmarket?.district?.some((name) => name === district.name)
        )
        .map((item) => ({
          ...item,
          path: item.boundaries,
          isEmpty: !summary?.industryCompareAnalysis?.find((e) => e.name === item.name)
            ?.tenantCount,
        }));
    } else {
      areaPolygonList = submarketMapDataList
        .filter((submarket) =>
          formData.districtAndSubmarket?.submarket?.some((id) => id === submarket.id)
        )
        .map((item) => ({
          ...item,
          path: item.boundaries,
          isEmpty: !summary?.industryCompareAnalysis?.find((e) => e.name === item.name)
            ?.tenantCount,
        }));
    }
    return areaPolygonList;
  }, [districtMapDataList, submarketMapDataList, boundariesType, formData, summary]);
};

export default useAreaPolygonList;
