import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useUserEvent from '@/hooks/useUserEvent';
import TopFiveTable from './TopFiveTable';
import RelocateReason from './RelocateReason';
import RelocateIndustry from './RelocateIndustry';
import RelocateArea from './RelocateArea';
import RelocateDestination from './RelocateDestination';
import AreaSummary from './AreaSummary';
import BlockWithTitle from './BlockWithTitle';
import useRelocateAnalysisStore from '../../store';
import { RelocateTrailDimensionType } from '@/constants/enums';
import styles from './style.module.less';

const ChartView = () => {
  const { sendUserEvent } = useUserEvent();

  const { chartData } = useRelocateAnalysisStore(
    useShallow((state) => ({
      chartData: state.chartData,
    }))
  );

  useEffect(() => {
    sendUserEvent({ page: '搬迁分析-列表模式' }, 'e_view');
  }, []);

  return (
    <div className={styles.chartView}>
      <div className={styles.dataBlock}>
        <BlockWithTitle
          areaSummary
          dataTitle="区域迁入迁出概览"
          showSelector
        >
          <AreaSummary data={chartData.areaOverview} />
        </BlockWithTitle>
      </div>

      <div className={styles.rowChart}>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="租户迁入来源区域 占比分布">
            <RelocateDestination
              type={RelocateTrailDimensionType.moveIn}
              data={chartData.originArea?.moveInChart}
            />
          </BlockWithTitle>
        </div>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="租户迁出去向区域 占比分布">
            <RelocateDestination
              type={RelocateTrailDimensionType.moveOut}
              data={chartData.originArea?.moveOutChart}
            />
          </BlockWithTitle>
        </div>
      </div>

      <div className={styles.rowChart}>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="租户迁入原因 占比分布">
            <RelocateReason
              type={RelocateTrailDimensionType.moveIn}
              data={chartData.reason?.moveInChart}
            />
          </BlockWithTitle>
        </div>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="租户迁出原因 占比分布">
            <RelocateReason
              type={RelocateTrailDimensionType.moveOut}
              data={chartData.reason?.moveOutChart}
            />
          </BlockWithTitle>
        </div>
      </div>

      <div className={styles.rowChart}>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="迁入租户一级行业 占比分布">
            <RelocateIndustry
              data={chartData.primaryIndustry?.moveInChart}
              type={RelocateTrailDimensionType.moveIn}
            />
          </BlockWithTitle>
        </div>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="迁出租户所属一级行业 占比分布">
            <RelocateIndustry
              data={chartData.primaryIndustry?.moveOutChart}
              type={RelocateTrailDimensionType.moveOut}
            />
          </BlockWithTitle>
        </div>
      </div>

      <div className={styles.rowChart}>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="迁入租户面积段 占比分布">
            <RelocateArea
              type={RelocateTrailDimensionType.moveIn}
              data={chartData.areaSection?.moveInChart}
            />
          </BlockWithTitle>
        </div>
        <div className={styles.dataBlock}>
          <BlockWithTitle dataTitle="迁出租户面积段 占比分布">
            <RelocateArea
              type={RelocateTrailDimensionType.moveOut}
              data={chartData.areaSection?.moveOutChart}
            />
          </BlockWithTitle>
        </div>
      </div>

      <div className={styles.dataBlock}>
        <BlockWithTitle
          dataTitle="搬迁租户（面积）TOP5名单"
          hideSwitch
        />
        <TopFiveTable
          data={chartData.top5Detail?.moveInDetail}
          dimension={RelocateTrailDimensionType.moveIn}
        />
        <TopFiveTable
          data={chartData.top5Detail?.moveOutDetail}
          dimension={RelocateTrailDimensionType.moveOut}
        />
      </div>
    </div>
  );
};

export default ChartView;
