import { useContext, createContext, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import classNames from 'classnames';
import { JLLSelect } from 'jlld';
import { RelocateTrailDimensionType } from '@/constants/enums';
import useRelocateAnalysisStore from '../../../store';
import styles from './style.module.less';
interface BlockWithTitleProps {
  dataTitle: string;
  areaSummary?: boolean;
  hideSwitch?: boolean;
  children?: JSX.Element;
  showSelector?: boolean;
}

export enum ChartType {
  area = 'area',
  count = 'count',
}

interface BlockWithTitleMode {
  mode: ChartType.area | ChartType.count;
  sortType?: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
}

export const BlockWithTitleContext = createContext<BlockWithTitleMode>({
  mode: ChartType.area,
  sortType: RelocateTrailDimensionType.moveIn,
});

export const useBlockWithTitleContext = () => useContext(BlockWithTitleContext);

const sortOptions = [
  {
    label: '按迁入租户占比降序',
    value: RelocateTrailDimensionType.moveIn,
  },
  {
    label: '按迁出租户占比降序',
    value: RelocateTrailDimensionType.moveOut,
  },
];

export default (props: BlockWithTitleProps) => {
  const [mode, setMode] = useState(ChartType.area);
  const [sortType, setSortType] = useState(RelocateTrailDimensionType.moveIn);
  const { chartData } = useRelocateAnalysisStore(
    useShallow((state) => ({
      chartData: state.chartData,
    }))
  );

  return (
    <BlockWithTitleContext.Provider value={{ mode, sortType }}>
      <div>
        <div className={styles.blockTitle}>
          <div className={styles.leftSide}>
            {props.areaSummary ? (
              <p>{chartData.currentCity}</p>
            ) : (
              <p className={styles.areaName}>{chartData.currentArea}</p>
            )}

            <div className={styles.gap} />

            <p className={styles.dataTitle}>{props.dataTitle}</p>
          </div>
          {props.hideSwitch ? (
            <></>
          ) : (
            <div className={styles.rightSide}>
              {props.showSelector ? (
                <JLLSelect
                  style={{ height: '30px', marginRight: '10px' }}
                  options={sortOptions}
                  defaultValue={sortType}
                  onChange={(val) => {
                    setSortType(val);
                  }}
                />
              ) : null}
              <div className={styles.switchButtonWrap}>
                <div
                  className={classNames(styles.switchButton, styles.left, {
                    [styles.active]: mode === ChartType.area,
                  })}
                  onClick={() => {
                    setMode(ChartType.area);
                  }}
                >
                  面积
                </div>
                <div
                  className={classNames(styles.switchButton, styles.right, {
                    [styles.active]: mode === ChartType.count,
                  })}
                  onClick={() => {
                    setMode(ChartType.count);
                  }}
                >
                  个数
                </div>
              </div>
            </div>
          )}
        </div>
        {props.children}
      </div>
    </BlockWithTitleContext.Provider>
  );
};
