import { PropertyLegend } from '@/components';
import { PropertyType } from '@/constants/enums';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import useIndustrialStore from '../../../store';
import { IndustrialPropertyItem } from '../../../types/apiTypes';
import PropertyInfoCard from '../PropertyInfoCard';
import { style, styles } from './config';
import { MassDataItem } from './types';
import { LabelMarker } from '@/components/LabelMarker';
import { useMapContext } from '@uiw/react-amap-map';
import { Marker } from '@uiw/react-amap-marker';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';
import { MassMarks } from 'jlld/es/JLLMap/MassMarks';

export default memo(() => {
  const { map } = useMapContext();
  const { mapMode, propertyList } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    propertyList: state.propertyList,
  }));

  const [activeItem, setActiveItem] = useState<IndustrialPropertyItem | undefined>();
  // 避免无法触发dom mark的out事件
  const domOverTimer = useRef<NodeJS.Timeout>();
  // state 更新没有ref快，也是作为hack使用,避免多次触发over事件
  const activeItemRef = useRef<MassDataItem | null>(null);

  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (propertyList.length && ['projectMarksMode', 'heatProjectMarksMode'].includes(mapMode)) {
      // 判断当前可视区域是否有marker，若没有定位到第一个点；若存在不做任何操作
      const hasPointInScreen = propertyList.some((item) => map?.getBounds()?.contains(item.center));
      if (!hasPointInScreen) {
        map?.setCenter(propertyList.filter((property) => !!property.center.length)[0].center);
      }
    }
  }, [propertyList, mapMode]);

  const propertyMarkList = useMemo(() => {
    return propertyList.map((property: IndustrialPropertyItem) => {
      const item = {
        id: property.id,
        originData: property,
        lnglat: property.center,
      };
      let index = style[property.propertyType][property.projectType];
      if (item.id === activeItem?.id) {
        if (property.propertyType === PropertyType.commercial) {
          index = 1;
        } else if (property.propertyType === PropertyType.industrial) {
          index = 4;
        }
      }
      return { ...item, style: index };
    });
  }, [propertyList, activeItem]);

  const onMouseOver = (e: any) => {
    clearTimeout(domOverTimer.current);
    domOverTimer.current = setTimeout(() => {
      onMouseOut();
    }, 300);
    const data: MassDataItem = e.data;
    if (activeItemRef.current?.id === data.id) {
      return;
    }

    clearTimeout(timer.current);
    setActiveItem(data.originData);
    activeItemRef.current = data;
  };

  const onMouseOut = () => {
    timer.current = setTimeout(() => {
      setActiveItem(undefined);
      activeItemRef.current = null;
    }, 300);
  };

  const onWindowMouseOver = () => {
    clearTimeout(timer.current);
  };

  const onWindowMouseOut = () => {
    setActiveItem(undefined);
    activeItemRef.current = null;
  };

  return (
    <>
      <AutoFitInfoWindow
        position={activeItem?.center}
        visible={!!activeItem?.center}
        arrowStyle={{ backgroundColor: '#fff' }}
        onMouseEnter={onWindowMouseOver}
        onMouseLeave={onWindowMouseOut}
      >
        <PropertyInfoCard data={activeItem} />
      </AutoFitInfoWindow>
      <MassMarks
        zIndex={999}
        zooms={mapMode === 'projectMarksMode' ? [13, 20] : [16, 20]}
        data={propertyMarkList}
        style={styles}
        onMouseOver={onMouseOver}
      />
      {activeItem?.center && (
        <Marker
          position={activeItem?.center}
          anchor="center"
          onMouseOver={() => {
            clearTimeout(domOverTimer.current);
          }}
          onMouseOut={onMouseOut}
        >
          {/* 加10仍然是避免多次触发，加大图标边缘 */}
          <div
            className="massMarkMask"
            style={{
              display: activeItem ? 'block' : 'none',
              width: 38,
              height: 38,
            }}
          ></div>
        </Marker>
      )}
      {propertyList.map((item) => (
        <LabelMarker
          key={item.id}
          zooms={[16, 20]}
          zIndex={20}
          name={item.name}
          position={item.center}
          text={{
            content: `${item.name}${`  ·  ${item.tenantCount}`}`,
            direction: 'top',
            offset: [0, -35],
            style: {
              fontSize: 12,
              fillColor: '#000',
              backgroundColor: '#fff',
              borderColor: '#fff',
              borderWidth: 12,
            },
          }}
        />
      ))}

      <PropertyLegend
        visible={mapMode === 'projectMarksMode' || mapMode === 'heatProjectMarksMode'}
        userProperty={[PropertyType.commercial, PropertyType.industrial]}
      />
    </>
  );
});
