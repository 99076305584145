import { PropertyType } from '@/constants/enums';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { queryPropertyList, querySummaryAndAnalysis } from '../api';
import { BoundariesType, MapFormValues, MapMode, PopupFormValues } from '../types';
import {
  AnalysisItem,
  IndustrialPropertyItem,
  industryCompareAnalysisItem,
  QueryIndustrialFormParams,
  Summary,
} from '../types/apiTypes';

const exceptKeys = ['propertyType', 'secondaryIndustries', 'districtAndSubmarket'];

const convertFormDataToQueryData = (formData: PopupFormValues) => {
  const queryData = {
    ...formData,
  } as any as QueryIndustrialFormParams;

  Object.keys(queryData).forEach((keyName) => {
    if (exceptKeys.includes(keyName)) {
      Reflect.deleteProperty(queryData, keyName);
    }
  });

  if (formData.propertyType?.[PropertyType.commercial]) {
    queryData.projectLevel = formData.propertyType?.[PropertyType.commercial];
  }

  if (formData.propertyType?.[PropertyType.industrial]) {
    queryData.industryProjectLevel = formData.propertyType?.[PropertyType.industrial];
  }

  if (formData.districtAndSubmarket?.district) {
    queryData.adminRegions = formData.districtAndSubmarket?.district;
  }

  if (formData.districtAndSubmarket?.submarket) {
    queryData.subMarkets = formData.districtAndSubmarket?.submarket;
  }

  if (formData.secondaryIndustries) {
    queryData.secondaryIndustries = Object.values(formData.secondaryIndustries).flat(1);
    queryData.primaryIndustries = Object.entries(formData.secondaryIndustries)
      .filter(([_, value]) => value.length)
      .map(([key]) => key);
  }

  return queryData;
};

const useIndustrialStore = create(
  immer(
    combine(
      {
        loading: {
          topForm: true,
        },
        industryCompetitivenessAnalysisList: [] as AnalysisItem[],
        propertyList: [] as IndustrialPropertyItem[],
        summary: {} as Summary | null,
        mapMode: 'districtAndMarketMode' as MapMode,
        boundariesType: 'district' as BoundariesType,
        // TODO: 这3个都没用
        submarket: '' as string,
        submarketId: '' as string,
        scaleToSubMarket: false,
        activeAreaData: {} as industryCompareAnalysisItem,
        formData: {} as PopupFormValues,
        center: [] as number[] | null | undefined,
      },
      (set, get) => ({
        queryPageData: async (data: PopupFormValues, mapParams?: MapFormValues | undefined) => {
          let params = data;
          if (mapParams) {
            params = { ...data, ...mapParams };
          }

          set({
            boundariesType: params.districtAndSubmarket?.district?.length
              ? 'district'
              : 'submarket',
            formData: data,
          });

          const [propertyList, summary] = await Promise.all([
            queryPropertyList(convertFormDataToQueryData(params)),
            querySummaryAndAnalysis(convertFormDataToQueryData(params)),
          ]);

          set({
            propertyList,
            industryCompetitivenessAnalysisList: summary.competitivenessAnalysis,
            summary,
          });
        },
        setQueryParam: (data: PopupFormValues) => {
          set({
            boundariesType: data.districtAndSubmarket?.district?.length ? 'district' : 'submarket',
            formData: data,
          });
        },

        toggleMapMode: (mode: MapMode) => {
          set({ mapMode: mode });
        },
        setSubMarket: (name: string) => {
          set({ submarket: name });
        },
        setSubMarketId: (id: string) => {
          set({ submarketId: id });
        },
        setSubMarketScale: (status: boolean) => {
          set({ scaleToSubMarket: status });
        },
        setActiveData: (data: industryCompareAnalysisItem) => {
          set({ activeAreaData: data });
        },
        setCenter: (center: number[] | null | undefined) => {
          set({ center });
        },
        setLoading: (key: 'topForm', status: boolean) => {
          set((state) => {
            state.loading[key] = status;
          });
        },
      })
    )
  )
);

// export const initTopFormValues = async () => {
//   queryIndustryPageOptions(getCurrentCity());
// };

export default useIndustrialStore;
