import { useMemo } from 'react';
import { useForm, useWatch } from 'jlld/es/form/Form';
import { OptionsToDefaultValues } from 'jlld/es/JLLCascader';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { JLLDatePicker, Radio, ConfigProvider, JLLSelect, JLLForm, JLLCascader } from 'jlld';
import { radioButtonConfig } from '@/jll';
import useReactShareUserStore from '@/store/reactShareUserStore';
import useReactShareSelectedStore, { useCity } from '@/store/reactShareSelectedStore';
import useRelocateAnalysisStore, {
  setDisplayMode,
  queryData,
  setTopFormQueryParam,
  setBoundariesType,
} from '../../store';
import { queryRelocateAnalysisPageOptions } from '@/store/commonOptions/pageActions';
import { getHalfYearAgoDate, getQuarter, getLastQuarter, getNextQuarter } from '@/utils/tool';
import {
  RelocateTrailDimensionType,
  RelocateAnalysisModeType,
  PropertyType,
  BoundariesType,
} from '@/constants/enums';
import { commercialAndIndustrialOptions } from '@/constants/options';
import { IndustrialPicker } from '../../../TopForms/commonFormItems';
import DistrictAndSubMarket from '../../../TopForms/DistrictAndSubMarket';
import useUserAuth from '@/hooks/useUserAuth';
import styles from '../../style.module.less';

interface DistrictAndSubmarket {
  submarket: string[];
  district: string[];
}
interface TopFilterForm {
  dimension: RelocateTrailDimensionType;
  rangeTime: [string, string];
  time: string;
  mode: RelocateAnalysisModeType;
  propertyType: [];
  districtAndSubmarket: DistrictAndSubmarket;
  secondaryIndustries: any;
  city: string;
}

const modeTypeOptions = [
  { label: '地图', value: RelocateAnalysisModeType.map },
  { label: '图表', value: RelocateAnalysisModeType.chart },
];

const [year, quarter] = getLastQuarter()
  .split('-Q')
  .map((item) => Number(item));
const endTimeStr = `${year}-Q${quarter}`;

const RelocateTrailTimeTypeMap = {
  halfYear: getQuarter(getHalfYearAgoDate()),
  oneYear: getNextQuarter(`${year - 1}-Q${quarter}`),
  twoYears: getNextQuarter(`${year - 2}-Q${quarter}`),
  threeYears: getNextQuarter(`${year - 3}-Q${quarter}`),
  custom: 'custom',
};

const timeTypeOptions = [
  { label: '近半年', value: RelocateTrailTimeTypeMap.halfYear },
  { label: '近1年', value: RelocateTrailTimeTypeMap.oneYear },
  { label: '近2年', value: RelocateTrailTimeTypeMap.twoYears },
  { label: '近3年', value: RelocateTrailTimeTypeMap.threeYears },
  { label: '自定义', value: RelocateTrailTimeTypeMap.custom },
];

const rangeStart = '2019';
const rangeEnd = `${new Date().getFullYear()}`;

const generateQueryParam = (formValue: TopFilterForm) => {
  console.log('formValue', formValue);
  const { city, secondaryIndustries, districtAndSubmarket, propertyType, rangeTime, time } =
    formValue;
  const param: any = {
    city,
    endTime: endTimeStr,
  };
  if (secondaryIndustries) {
    const primaryIndustries = Object.keys(secondaryIndustries);
    const temp: string[] = [];
    param.primaryIndustries = primaryIndustries;
    primaryIndustries.forEach((keys) => {
      temp.push(...secondaryIndustries[keys]);
    });
    param.secondaryIndustries = temp;
  }

  if (districtAndSubmarket) {
    const { submarket, district } = districtAndSubmarket;
    if (submarket) {
      param.subMarketId = submarket[0];
    } else {
      param.district = district[0];
    }
  }

  if (propertyType) {
    param.projectLevel = propertyType[PropertyType.commercial] || [];
    param.industryProjectLevel = propertyType[PropertyType.industrial] || [];
  }

  if (time) {
    param.startTime = time;
  }

  if (time === RelocateTrailTimeTypeMap.custom) {
    param.startTime = rangeTime[0];
    param.endTime = rangeTime[1];
  }
  return param;
};

export default () => {
  const [form] = useForm<TopFilterForm>();
  const city = useCity();
  const user = useReactShareUserStore.getState().user;
  const { selectableCityList, userDefaultCity, dimension, defaultSubmarketId } =
    useRelocateAnalysisStore(
      useShallow((state) => ({
        selectableCityList: state.selectableCityList,
        userDefaultCity: state.userDefaultCity,
        defaultSubmarketId: state.defaultSubmarketId,
        dimension: state.dimension,
      }))
    );

  const setSelectedCity = useReactShareSelectedStore((state) => state.setSelectedCity);

  const time = useWatch('time', form);

  const currCommercialAndIndustrialOptions = useMemo(() => {
    const result = [];

    if (user.propertyType.includes(PropertyType.commercial)) {
      result.push(commercialAndIndustrialOptions[0]);
    }
    if (user.propertyType.includes(PropertyType.industrial)) {
      result.push(commercialAndIndustrialOptions[1]);
    }
    return result;
  }, [commercialAndIndustrialOptions]);

  const hasListAuth = useUserAuth('front:project:relocationtrace:list');

  const getFormDefaultValues = (): Partial<any> => {
    console.log('getFormDefaultValues');

    const queryParam = {
      city: userDefaultCity,
      time: timeTypeOptions[3].value,
      mode: RelocateAnalysisModeType.map,
      districtAndSubmarket: {
        submarket: [defaultSubmarketId],
      },
      propertyType: OptionsToDefaultValues(currCommercialAndIndustrialOptions, [0]) as Record<
        string,
        string[]
      >,
    };
    // setTopFormQueryParam(queryParam);
    return queryParam;
  };

  const onFormValueChange = (changedValues: Partial<TopFilterForm>, allValues: TopFilterForm) => {
    if (changedValues.time === RelocateTrailTimeTypeMap.custom) {
      return;
    }

    console.log('onFormValueChange', changedValues, allValues);

    if (changedValues.mode) {
      setDisplayMode(changedValues.mode);
      const param = {
        ...generateQueryParam(allValues),
        dimension,
      };
      queryData(param, changedValues.mode);
      return;
    }

    if (changedValues.districtAndSubmarket) {
      const { submarket } = changedValues.districtAndSubmarket;
      setBoundariesType(submarket ? BoundariesType.submarket : BoundariesType.district);
    }

    const queryParam = {
      ...generateQueryParam(allValues),
      dimension,
    };

    if (changedValues.city) {
      const newSubmarketId = selectableCityList.find(
        (item) => item.value === changedValues.city
      ).defaultSubmarketId;
      form.setFieldValue('districtAndSubmarket', {
        submarket: [newSubmarketId],
      });
      queryParam.subMarketId = newSubmarketId;
      queryParam.district = '';
      setBoundariesType(BoundariesType.submarket);
      queryRelocateAnalysisPageOptions(changedValues.city);
      setSelectedCity(changedValues.city);
    }

    setTopFormQueryParam(queryParam);
    queryData(queryParam);
  };

  const customDropdownRender = (menu) => (
    <div>
      {menu}
      <p
        style={{
          color: '#637177',
          fontSize: '12px',
          fontWeight: 400,
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        *目前仅覆盖部分城市，未来将逐步拓展至其他城市，敬请期待
      </p>
    </div>
  );
  return (
    <div>
      <JLLForm
        form={form}
        layout="inline"
        initialValues={getFormDefaultValues()}
        onValuesChange={onFormValueChange}
      >
        <JLLForm.Item name="city">
          <JLLSelect
            style={{ width: 160 }}
            options={selectableCityList}
            dropdownRender={customDropdownRender}
          />
        </JLLForm.Item>

        <JLLForm.Item style={{ width: 160 }}>
          <DistrictAndSubMarket
            single
            hideRightAll
            searchPlaceholder="请输入搬迁分析的目标区域"
          />
        </JLLForm.Item>

        <JLLForm.Item style={{ width: 160 }}>
          <IndustrialPicker
            placeholder="选择行业类型"
            search
            searchPlaceholder="请输入搬迁分析的目标行业"
          />
        </JLLForm.Item>
        <JLLForm.Item
          name="propertyType"
          style={{ width: 160 }}
        >
          <JLLCascader
            search
            searchPlaceholder="请输入搬迁分析的物业类型"
            className="headerFormItem"
            placeholder="选择物业类型"
            options={currCommercialAndIndustrialOptions}
          />
        </JLLForm.Item>

        <JLLForm.Item name="time">
          <JLLSelect
            className={styles.timeSelect}
            options={timeTypeOptions}
          />
        </JLLForm.Item>
        {time === RelocateTrailTimeTypeMap.custom ? (
          <JLLForm.Item
            name="rangeTime"
            style={{ marginLeft: '8px' }}
          >
            <JLLDatePicker.JLLRangePicker
              allowClear={false}
              picker="quarter"
              separator="至"
              className={styles.transactionTimeRangePicker}
              minDate={dayjs(rangeStart)}
              maxDate={dayjs(rangeEnd)}
            />
          </JLLForm.Item>
        ) : (
          <></>
        )}
        <ConfigProvider
          theme={{
            components: {
              Radio: radioButtonConfig,
            },
          }}
        >
          <JLLForm.Item
            name="mode"
            style={{ marginLeft: 'auto', marginRight: 0 }}
            hidden={!hasListAuth}
          >
            <Radio.Group
              options={modeTypeOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </JLLForm.Item>
        </ConfigProvider>
      </JLLForm>
    </div>
  );
};
