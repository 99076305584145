import ReactECharts from 'echarts-for-react';
import useIndustrialStore from '../../../store';

const TenantPieChart = (props: { dataType: string }) => {
  const summary = useIndustrialStore((state) => state.summary);

  const getOption = () => {
    const pieColors = ['#FF838B', '#F5B788', '#875C97', '#D8D8D9'];
    const option = {
      grid: {
        top: '20px',
        left: '40px',
        right: '10px',
        bottom: '20px',
      },
      tooltip: {
        trigger: 'item',
        borderColor: 'transparent',
        formatter(params: any) {
          const str = `<div style="width: 140px;"><p style="height: 20px; line-height: 16px; color: #01151c; margin-bottom: 5px; font-weight: bold;">
              <span>${params.name}</span>
            </p>
            <p style="display: flex; align-items: center;">
            <span style="width: 70px; color: #60666E; flex: 1;">${
              props.dataType === 'area' ? '面积占比' : '个数占比'
            }</span>
            <span style="color: #01151c;">${params.percent.toFixed(1)}%</span>
          </p>`;
          return `${str}</div>`;
        },
      },
      legend: {
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
        bottom: 0,
      },
      series: [
        {
          type: 'pie',
          radius: '85%',
          bottom: 30,
          label: {
            show: false,
          },
          data: summary?.tenantSourceDistribution?.map((item) => {
            return {
              value: props.dataType === 'area' ? item.areaPercentage : item.countPercentage,
              name: item.name,
            };
          }),
          itemStyle: {
            color: (colors: any) => pieColors[colors.dataIndex % pieColors.length],
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
    return option;
  };

  return (
    <div>
      {summary?.tenantSourceDistribution?.length > 0 ? (
        <ReactECharts
          option={getOption()}
          notMerge
          lazyUpdate
          style={{ height: '200px' }}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
          // opts={}
        />
      ) : (
        <div className="chartEmpty">
          <img src="/empty-chart.png" />
          <p>暂无数据</p>
        </div>
      )}
    </div>
  );
};

export default TenantPieChart;
