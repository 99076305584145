import { RelocateTrailDimensionType, RelocateTrailModeType } from '@/pages/Z-ProjectDetail/types';
import { getHalfYearAgoDate, getLastQuarter, getNextQuarter, getQuarter } from '@/utils/tool';
import { PickerType } from 'jlld/es/JLLDatePicker';

export const dimensionTypeOptions = [
  { label: '看迁入', value: RelocateTrailDimensionType.moveIn },
  { label: '看迁出', value: RelocateTrailDimensionType.moveOut },
];

export const modeTypeOptions = [
  { label: '地图', value: RelocateTrailModeType.map },
  { label: '列表', value: RelocateTrailModeType.list },
];

export const [year, quarter] = getLastQuarter()
  .split('-Q')
  .map((item) => Number(item));
export const endTimeStr = `${year}-Q${quarter}`;

export const RelocateTrailTimeTypeMap = {
  halfYear: getQuarter(getHalfYearAgoDate()),
  oneYear: getNextQuarter(`${year - 1}-Q${quarter}`),
  twoYears: getNextQuarter(`${year - 2}-Q${quarter}`),
  threeYears: getNextQuarter(`${year - 3}-Q${quarter}`),
  custom: 'custom',
};

export const timeTypeOptions = [
  {
    label: '近半年',
    value: RelocateTrailTimeTypeMap.halfYear,
    pickerType: 'year' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '近1年',
    value: RelocateTrailTimeTypeMap.oneYear,
    pickerType: 'year' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '近2年',
    value: RelocateTrailTimeTypeMap.twoYears,
    pickerType: 'year' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '近3年',
    value: RelocateTrailTimeTypeMap.threeYears,
    pickerType: 'year' as PickerType,
    pickerDisabled: true,
  },
  {
    label: '自定义',
    value: RelocateTrailTimeTypeMap.custom,
    pickerType: 'year' as PickerType,
    pickerDisabled: false,
  },
];
