import React, { memo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Marker } from '@uiw/react-amap-marker';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';
import DistrictAndMarketInfoCard from '../DistrictAndMarketInfoCard';
import AreaPolygon, { AreaPolygonType } from '@/components/AreaPolygon';
import useZoomChange from 'jlld/es/JLLMap/useZoomChange';
import { Position } from 'jlld/es/JLLMap/types';
import styles from './style.module.less';

function checkIntersect(rect1: DOMRect, rect2: DOMRect): boolean {
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
}

const MarkerStyle = {
  dot: 'dot',
  label: 'label',
};

export interface PathItem {
  areaName?: string;
  name?: string;
  path: Position[] | Position[][];
  center: Position;
  isEmpty: boolean;
  current?: boolean;
  tenantNum: string;
}

export interface DistrictAndMarketPolygonProps {
  pathList: PathItem[];
  showType?: 'borderOnly';
}

export default memo((props: DistrictAndMarketPolygonProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [highlightArea, setHighlightArea] = useState<string | null>(null);
  const [markerStyle, setMarkerStyle] = useState(MarkerStyle.dot);
  const timer = useRef<NodeJS.Timeout>();
  const windowTimer = useRef<NodeJS.Timeout>();
  useZoomChange((zoom) => {
    // 计算边界碰撞，如果有重叠区域，第二个重叠的开始隐藏
    // 转为数组
    const allMarkers = [...document.querySelectorAll('.areaNameDomMark')] as HTMLElement[];
    setMarkerStyle(zoom > 13 ? MarkerStyle.label : MarkerStyle.dot);
    allMarkers.forEach((element) => {
      element.style.display = 'block';
    });
    const checkAndHide = (list: HTMLElement[]) => {
      const nextMarkerList: HTMLElement[] = [];
      const currentMarker = list[0];

      for (let i = 1; i < list.length; i++) {
        if (
          checkIntersect(currentMarker.getBoundingClientRect(), list[i].getBoundingClientRect())
        ) {
          list[i].style.display = 'none';
        } else {
          nextMarkerList.push(list[i]);
        }
      }
      if (nextMarkerList.length) {
        checkAndHide(nextMarkerList);
      }
    };
    checkAndHide(allMarkers);
  });

  return props.pathList.map((item) => {
    let styleType: AreaPolygonType;
    if (props.showType) {
      styleType = props.showType;
    } else if (item.isEmpty) {
      styleType = 'relocateEmpty';
    } else if (item.current) {
      if (item.areaName === highlightArea) {
        styleType = 'relocateCurrentHighlight';
      } else {
        styleType = 'relocateCurrent';
      }
    } else {
      if (item.areaName === highlightArea) {
        styleType = 'relocateOtherHighlight';
      } else {
        styleType = 'relocateOther';
      }
    }
    return (
      <React.Fragment key={item.areaName || item.name}>
        <AreaPolygon
          type={styleType}
          path={item.path}
          zIndex={9999}
          onMouseOver={() => {
            clearTimeout(windowTimer.current);
            clearTimeout(timer.current);
            setHighlightArea(item.areaName! || item.name!);
          }}
          onMouseOut={() => {
            timer.current = setTimeout(() => {
              setHighlightArea(null);
            }, 400);
          }}
        />
        {markerStyle === MarkerStyle.dot ? (
          <Marker
            position={item.center}
            anchor="center"
            onClick={() => {
              setActiveId(item.areaName! || item.name!);
            }}
          >
            <div className={styles.markerDom}>
              {item.current ? (
                <div className={classNames(styles.dot, styles.current)}>
                  {/* TODO 等kyle回来更新图标 */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 7C14 8.19879 13.6484 9.31546 13.0428 10.2525C11.3489 13.1395 8 15 8 15C8 15 4.65115 13.1395 2.95722 10.2525C2.35157 9.31546 2 8.19879 2 7.00001V7C2 3.68629 4.68629 1 8 1C11.3137 1 14 3.68629 14 7ZM8.0002 9.00019C9.10476 9.00019 10.0002 8.10476 10.0002 7.0002C10.0002 5.89563 9.10476 5.0002 8.0002 5.0002C6.89563 5.0002 6.0002 5.89563 6.0002 7.0002C6.0002 8.10476 6.89563 9.00019 8.0002 9.00019Z"
                      fill="white"
                    />
                  </svg>
                </div>
              ) : !item.isEmpty ? (
                <div className={classNames(styles.dot, styles.otherDot)}>{item.tenantNum}</div>
              ) : (
                <></>
              )}
            </div>
          </Marker>
        ) : !item.isEmpty ? (
          <Marker
            position={item.center}
            anchor="center"
            onClick={() => {
              setActiveId(item.areaName || item.name);
            }}
          >
            <div className={styles.markerDom}>
              <div className={classNames(styles.expendDot, { [styles.current]: item.current })}>
                {item.current ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 7C14 8.19879 13.6484 9.31546 13.0428 10.2525C11.3489 13.1395 8 15 8 15C8 15 4.65115 13.1395 2.95722 10.2525C2.35157 9.31546 2 8.19879 2 7.00001V7C2 3.68629 4.68629 1 8 1C11.3137 1 14 3.68629 14 7ZM8.0002 9.00019C9.10476 9.00019 10.0002 8.10476 10.0002 7.0002C10.0002 5.89563 9.10476 5.0002 8.0002 5.0002C6.89563 5.0002 6.0002 5.89563 6.0002 7.0002C6.0002 8.10476 6.89563 9.00019 8.0002 9.00019Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <></>
                )}
                <p
                  className={classNames(styles.areaName, {
                    [styles.currentAreaName]: item.current,
                  })}
                >
                  {item.areaName || item.name}
                </p>
                {item.current ? <></> : <p>{item.tenantNum}</p>}
              </div>
            </div>
          </Marker>
        ) : (
          <></>
        )}

        {!item.isEmpty && activeId === item.areaName && styleType !== 'relocateEmpty' && (
          <AutoFitInfoWindow
            visible
            arrowStyle={{ backgroundColor: '#fff' }}
            position={item.center}
          >
            <DistrictAndMarketInfoCard
              data={item}
              onClose={() => {
                setActiveId(null);
              }}
            />
          </AutoFitInfoWindow>
        )}
      </React.Fragment>
    );
  });
});
