import ReactECharts from 'echarts-for-react';
import Empty from '@/components/Empty';
import { PageWithWatermark } from '@/components';
import { InAndOutChartItem } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '@/constants/enums';
import { thousandSplitNumber } from '@/utils/tool';
import { DataObject } from '@/types';
import { useBlockWithTitleContext, ChartType } from '../BlockWithTitle';

interface RelocateReasonProps {
  data: InAndOutChartItem[];
  type: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
}
const pieColors = ['#D1B9A7', '#40798D', '#95C6D5', '#D4D4D4'];

const RelocateReason = (props: RelocateReasonProps) => {
  const { mode } = useBlockWithTitleContext();
  const dataObj: DataObject = {};

  const getData = () => {
    return props.data.map((item) => {
      dataObj[item.name] = item;
      return {
        value: mode === ChartType.area ? item.tenantAreaRatio : item.tenantCountRatio,
        name: item.name,
      };
    });
  };

  const getOption = () => {
    const option = {
      grid: {
        top: '20px',
        left: '40px',
        right: '10px',
        bottom: '20px',
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'none',
        },
        confine: true,
        formatter: (params: any) => {
          console.log('params', params);
          const { name, value, color } = params;
          return `
            <div style="color: #01151C;">
              <p style="margin-bottom: 8px; font-weight: bold;">${name}</p>
              <div style="margin-bottom: 4px;">
                <span style="display:inline-block;width:12px; height:12px;background: ${color};"></span>
                <span style="display:inline-block; width:130px">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}占比</span>
                ${value && typeof value === 'number' ? `${value}%` : '--'}
              </div>
              <div style="display: flex; justify-content: space-between;">
                <span style="display:inline-block; width:130px; ">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}值</span>
                <span>${thousandSplitNumber(mode === ChartType.area ? dataObj[name].tenantArea : dataObj[name].tenantCount)}${mode === ChartType.area ? '㎡' : '个'}</span>
              </div>
            </div>
          `;
        },
      },
      legend: {
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
        bottom: 0,
        left: 0,
      },
      series: [
        {
          type: 'pie',
          radius: '70%',
          bottom: 30,
          data: getData(),
          itemStyle: {
            color: (colors: any) => pieColors[colors.dataIndex % pieColors.length],
          },
          label: {
            show: true,
            position: 'outer',
          },
          labelLine: {
            show: true, // 确保指示线一直显示
            length: 30, // 指示线的长度，可以根据需要调整
            lineStyle: {
              color: '#CED5D8', // 指示线的颜色
              width: 1, // 指示线的宽度
              type: 'solid', // 指示线的类型
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
    return option;
  };

  return (
    <div>
      {props.data?.length > 0 ? (
        <PageWithWatermark>
          <ReactECharts
            option={getOption()}
            notMerge
            lazyUpdate
            style={{ height: '340px' }}
          />
        </PageWithWatermark>
      ) : (
        <div className="chartEmpty">
          <Empty
            text="当前筛选条件下，没有相关数据"
            useSolsticeStyle
          />
        </div>
      )}
    </div>
  );
};

export default RelocateReason;
