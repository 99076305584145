import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  getRelocateAnalysisMapData,
  getRelocateAnalysisChartData,
  getCityList,
} from '../../../api';
import {
  RelocateTrailDimensionType,
  RelocateAnalysisModeType,
  BoundariesType,
} from '@/constants/enums';
import { queryRelocateAnalysisPageOptions } from '@/store/commonOptions/pageActions';
import {
  QueryRelocateAnalysisMapParams,
  QueryRelocateAnalysisChartParams,
  QueryRelocateAnalysisMapResult,
  QueryRelocateAnalysisChartResult,
} from '../../../types/apiTypes';
import { getLastQuarter, getNextQuarter } from '@/utils/tool';
import { Position } from 'jlld/es/JLLMap/types';

type Dimension = RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
type Mode = RelocateAnalysisModeType.map | RelocateAnalysisModeType.chart;

interface State {
  loading: boolean;
  dimension: Dimension;
  displayMode: Mode;
  mapData: QueryRelocateAnalysisMapResult;
  chartData: QueryRelocateAnalysisChartResult;
  boundariesType: BoundariesType.district | BoundariesType.submarket;
  selectableCityList: any[];
  mapCenter: [number, number];
  userDefaultCity: string;
  defaultSubmarketId: string;
  topFormQueryParam: any;
  noAuthCity: boolean;
}

const state: State = {
  loading: true,
  dimension: RelocateTrailDimensionType.moveIn,
  displayMode: RelocateAnalysisModeType.map,
  boundariesType: BoundariesType.submarket,
  mapData: {} as QueryRelocateAnalysisMapResult,
  chartData: {} as QueryRelocateAnalysisChartResult,
  selectableCityList: [],
  mapCenter: [104.040969, 30.616895],
  userDefaultCity: '',
  defaultSubmarketId: '',
  topFormQueryParam: {},
  noAuthCity: false,
};

const useRelocateAnalysisStore = create<State>()(immer(() => state));

const set = useRelocateAnalysisStore.setState;
const get = useRelocateAnalysisStore.getState;

export const initRelocateAnalysis = async (userCity: string) => {
  const cities = await getCityList();
  let usedCity;

  const [year, quarter] = getLastQuarter()
    .split('-Q')
    .map((item) => Number(item));
  const startTime = getNextQuarter(`${year - 3}-Q${quarter}`);
  const endTime = `${year}-Q${quarter}`;

  if (cities) {
    const temp = cities.find((item: any) => item.city === userCity);
    usedCity = temp || cities[0];
  } else {
    usedCity = {};
    set({
      loading: false,
      noAuthCity: true,
    });
    return;
  }

  await queryRelocateAnalysisPageOptions(usedCity.city);

  const queryParam = {
    ...get().topFormQueryParam,
    city: usedCity.city,
    subMarketId: usedCity.defaultSubmarketId,
    startTime,
    endTime,
    dimension: get().dimension,
  };
  await queryRelocateAnalysisMapData(queryParam);

  set({
    selectableCityList: cities?.map((item) => ({
      value: item.city,
      label: item.city,
      defaultSubmarketId: item.defaultSubmarketId,
    })),
    topFormQueryParam: queryParam,
    userDefaultCity: usedCity.city || '',
    defaultSubmarketId: usedCity.defaultSubmarketId,
    loading: false,
    noAuthCity: false,
  });
};

export const querySelectableCityList = async () => {
  const result = await getCityList();
  const city = result.map((item) => ({
    value: item.city,
    label: item.city,
  }));
  set({
    selectableCityList: city,
  });
};

export const queryRelocateAnalysisMapData = async (queryParam: QueryRelocateAnalysisMapParams) => {
  const mapData = await getRelocateAnalysisMapData(queryParam);
  set({
    mapData,
    mapCenter: mapData?.curArea?.subMarketBoundary?.[0],
  });
};

export const queryRelocateAnalysisChartData = async (
  queryParam: QueryRelocateAnalysisChartParams
) => {
  const chartData = await getRelocateAnalysisChartData(queryParam);
  set({
    chartData,
  });
};

export const queryData = async (
  queryParam: QueryRelocateAnalysisMapParams | QueryRelocateAnalysisChartParams,
  m?: Mode
) => {
  const mode = m || get().displayMode;
  if (mode === RelocateAnalysisModeType.chart) {
    queryRelocateAnalysisChartData(queryParam);
  } else {
    queryRelocateAnalysisMapData(queryParam);
  }
};

export const setTopDimension = (dimension: Dimension) => {
  set({
    dimension,
  });
};

export const setTopFormQueryParam = (topFormQueryParam: any) => {
  set({
    topFormQueryParam,
  });
};

export const setDisplayMode = (displayMode: Mode) => {
  set({
    displayMode,
  });
};
export const setMapCenter = (mapCenter: Position) => {
  set({
    mapCenter,
  });
};

export const setBoundariesType = (
  boundariesType: BoundariesType.district | BoundariesType.submarket
) => {
  set({
    boundariesType,
  });
};

export const resetState = () => {
  set({
    ...state,
  });
};

export const setLoading = (loading: boolean) => {
  set({
    loading,
  });
};

export default useRelocateAnalysisStore;
