import { Polygon, PolygonProps } from '@uiw/react-amap-polygon';

export const normal = {
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  fillColor: '#A9000B',
  fillOpacity: 0.32,
};

export const empty = {
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  fillColor: '#4B5B61',
  fillOpacity: 0.32,
};

export const active = {
  strokeColor: '#E30613',
  strokeWeight: 2,
  fillColor: '#A9000B',
  fillOpacity: 0.48,
};

export const relocateCurrent = {
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  fillColor: '#A9000B',
  fillOpacity: 0.32,
};

export const relocateCurrentHighlight = {
  strokeColor: '#DE0614',
  strokeWeight: 2,
  fillColor: '#DE0614',
  fillOpacity: 0.6,
};

export const relocateOther = {
  strokeColor: '#FFFFFF',
  strokeWeight: 2,
  fillColor: '#4B5B61',
  fillOpacity: 0.32,
};

export const relocateOtherHighlight = {
  strokeColor: '#33444A',
  strokeWeight: 2,
  fillColor: '#33444A',
  fillOpacity: 0.6,
};

export const relocateEmpty = {
  strokeColor: '#959EA1',
  strokeWeight: 2,
  fillColor: '#fff',
  fillOpacity: 0,
};

export const borderOnly = {
  strokeColor: '#FC888F',
  strokeWeight: 3,
  fillColor: '#fff',
  fillOpacity: 0,
};

const styleType = {
  active,
  normal,
  empty,
  borderOnly,
  relocateCurrent,
  relocateCurrentHighlight,
  relocateOther,
  relocateOtherHighlight,
  relocateEmpty,
};

export type AreaPolygonType =
  | 'active'
  | 'normal'
  | 'empty'
  | 'borderOnly'
  | 'relocateEmpty'
  | 'relocateOther'
  | 'relocateOtherHighlight'
  | 'relocateCurrent'
  | 'relocateCurrentHighlight';

export interface AreaPolygonProps extends PolygonProps {
  type: AreaPolygonType;
}

const AreaPolygon = (props: AreaPolygonProps) => {
  const { type, ...rest } = props;
  const style = styleType[type];

  return (
    <Polygon
      {...style}
      {...rest}
    />
  );
};

export default AreaPolygon;
