import { DistrictPathData } from '@/types';
import { Position } from 'jlld/es/JLLMap/types';

const Area = (p0: Position, p1: Position, p2: Position) => {
  let area = 0.0;
  area =
    p0[0] * p1[1] + p1[0] * p2[1] + p2[0] * p0[1] - p1[0] * p0[1] - p2[0] * p1[1] - p0[0] * p2[1];
  return area / 2;
};

export const makePolygonArea = (polygonRange: Position[]) => {
  if (polygonRange.length === 1) {
    const centerPoint = new window.AMap.LngLat(...polygonRange[0]);
    const Apoint = centerPoint.offset!(2000, 500);
    const Bpoint = centerPoint.offset!(-2000, 500);
    const Cpoint = centerPoint.offset!(0, -600);
    return [Apoint, Bpoint, Cpoint].map((item: any) => [item.lng, item.lat]);
  }

  return polygonRange;
};
// 判断多边形重心位置
export const getPolygonAreaCenter = (
  points: Position[],
  basePoints?: Position[]
): Position | null => {
  if (!points || points.length < 3) {
    return null;
  }
  let sum_x = 0;
  let sum_y = 0;
  let sum_area = 0;
  let p1 = points[1];
  let p2;
  let area;
  for (let i = 2; i < points.length; i++) {
    p2 = points[i];
    area = Area(points[0], p1, p2);
    sum_area += area;
    sum_x += (points[0][0] + p1[0] + p2[0]) * area;
    sum_y += (points[0][1] + p1[1] + p2[1]) * area;
    p1 = p2;
  }
  const xx = sum_x / sum_area / 3;
  const yy = sum_y / sum_area / 3;
  if (((window.AMap as any)?.GeometryUtil as any).isPointInRing([xx, yy], basePoints || points)) {
    // 点是否落到多边形内部
    return [xx, yy];
  }
  // 删除一个点。继续找
  const newpoints = [...points];
  newpoints.pop();
  return getPolygonAreaCenter(newpoints, basePoints || points);
};

export const getDistrictPath = async (
  cityCode: string,
  selectedDistrictNameList: string[]
): Promise<DistrictPathData[]> => {
  if (['510100', '441900', '620200', '442000', '320500'].includes(cityCode)) {
    const jsonData = await fetch(`/mapjson/${cityCode}.json`).then((res) => res.json());
    // '成都市,东莞市,嘉峪关市,中山市,苏州市' 自定义行政区
    const result = jsonData.features
      .filter((i: any) =>
        selectedDistrictNameList.some((item) => {
          return i.properties.name.includes(item) || item.includes(i.properties.name);
        })
      )
      .map((i: any) => ({
        center: i.properties.cp,
        name: i.properties.name,
        adcode: i.id,
        boundaries: i.geometry.coordinates,
        key: i.id,
      }));
    return result;
  }
  const { AMap } = window as any;
  const districtSearch = new AMap.DistrictSearch({ extensions: 'all', level: 'district' });

  const districtResult: any[] = await Promise.all(
    selectedDistrictNameList?.map(
      (districtName) =>
        new Promise((resolve) => {
          districtSearch.search(districtName, (status: any, result: any) => {
            try {
              if (result.districtList) {
                const districtData = result.districtList.find(
                  (item: any) => item.adcode.slice(0, 3) === cityCode.slice(0, 3)
                );
                if (districtData) {
                  districtData.center = [districtData.center.lng, districtData.center.lat];
                }

                // if (districtData?.boundaries) {
                //   districtData.boundaries = districtData.boundaries.map((item: any) => {
                //     return item.map((i: any) => [i.lng, i.lat]);
                //   });
                // }
                // console.log('districtData', districtData);
                resolve(districtData);
              } else {
                resolve(null);
              }
            } catch (e) {
              resolve(null);
              console.error(e);
            }
            // 查询成功时，result即为对应的行政区信息
          });
        })
    )
  );

  return districtResult.filter((item) => item);
};
