import ReactECharts from 'echarts-for-react';
import Empty from '@/components/Empty';
import { InAndOutChartItem } from '../../../../../types/apiTypes';
import { RelocateTrailDimensionType } from '@/constants/enums';
import { PageWithWatermark } from '@/components';
import { thousandSplitNumber } from '@/utils/tool';
import { DataObject } from '@/types';
import { useBlockWithTitleContext, ChartType } from '../BlockWithTitle';

interface RelocateAreaProps {
  data: InAndOutChartItem[];
  type: RelocateTrailDimensionType.moveIn | RelocateTrailDimensionType.moveOut;
}

const RelocateDestination = (props: RelocateAreaProps) => {
  const { mode } = useBlockWithTitleContext();

  console.log(mode);
  const getOption = () => {
    const xAxisData: string[] = [];
    const seriesData: number[] = [];
    const dataObj: DataObject = {};

    props.data.forEach((item) => {
      xAxisData.push(item.name);
      seriesData.push(mode === ChartType.area ? item.tenantAreaRatio : item.tenantCountRatio);
      dataObj[item.name] = item;
    });

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        confine: true,
        formatter: (params: any) => {
          const [{ axisValue, value }] = params;
          return `
            <div style="color: #01151C;">
              <p style="margin-bottom: 8px; font-weight: bold;">${axisValue}</p>
              <div style="margin-bottom: 4px;">
                <span style="display:inline-block;width:12px; height:12px;background: ${props.type === RelocateTrailDimensionType.moveIn ? '#D1B9A7' : '#40798D'};"></span>
                <span style="display:inline-block; width:130px">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}占比</span>
                ${value && typeof value === 'number' ? `${value}%` : '--'}
              </div>
              <div style="display: flex; justify-content: space-between;">
                <span style="display:inline-block; width:130px; ">${props.type === RelocateTrailDimensionType.moveIn ? '迁入' : '迁出'}租户${mode === ChartType.area ? '面积' : '个数'}值</span>
                <span>${thousandSplitNumber(mode === ChartType.area ? dataObj[axisValue].tenantArea : dataObj[axisValue].tenantCount)}${mode === ChartType.area ? '㎡' : '个'}</span>
              </div>
            </div>
          `;
        },
      },
      legend: {
        icon: 'rect',
        itemWidth: 12,
        itemHeight: 12,
        left: '0px',
        bottom: '0px',
      },
      grid: {
        top: '30px',
        left: '42px',
        right: '10px',
        bottom: '50px',
      },
      xAxis: [
        {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 10,
          },
          data: xAxisData,
        },
      ],
      yAxis: [
        {
          type: 'value',
          nameTextStyle: {
            padding: [0, 0, 0, -30],
          },
          axisLabel: {
            formatter: (value: any) => `${value}%`,
          },
        },
      ],
      series: [
        {
          name: props.type === RelocateTrailDimensionType.moveIn ? '迁入租户' : '迁出租户',
          color: props.type === RelocateTrailDimensionType.moveIn ? '#D1B9A7' : '#40798D',
          type: 'bar',
          barWidth: 40,
          data: seriesData,
        },
      ],
    };
    return option;
  };

  return (
    <div>
      {props.data?.length > 0 ? (
        <PageWithWatermark>
          <ReactECharts
            option={getOption()}
            notMerge
            lazyUpdate
            style={{ height: '340px' }}
          />
        </PageWithWatermark>
      ) : (
        <div className="chartEmpty">
          <Empty
            text="当前筛选条件下，没有相关数据"
            useSolsticeStyle
          />
        </div>
      )}
    </div>
  );
};

export default RelocateDestination;
