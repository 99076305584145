import { HeatMap } from '@/components';
import useIndustrialStore from '@/pages/D-IndustrialAnalysis/store';
import { memo, useMemo } from 'react';

export default memo(() => {
  const { mapMode, propertyList } = useIndustrialStore((state) => ({
    mapMode: state.mapMode,
    propertyList: state.propertyList,
  }));
  const showHeatMap = mapMode === 'heatMapMode' && propertyList.length > 0;

  const heatMapDataList = useMemo(() => {
    return propertyList.map((property) => {
      const { id, center, tenantCount } = property;
      const [lng, lat] = center;
      return {
        id,
        lng,
        lat,
        count: tenantCount,
      };
    });
  }, [propertyList]);

  return (
    showHeatMap && (
      <HeatMap
        key={mapMode}
        dataList={heatMapDataList}
        max={100}
        options={{
          visible: showHeatMap,
          radius: 40,
          zooms: [0, 13],
          gradient: {
            0: 'rgba(200, 21, 66, 0)',
            0.5: 'rgba(200, 21, 66, 0.5)',
            0.7: 'rgba(227, 6, 19, 0.6)',
            0.9: 'rgba(238, 127, 47, 0.7)',
            1: 'rgba(255, 192, 3, 0.8)',
          },
        }}
      />
    )
  );
});
