import { JLLDatePicker } from 'jlld';
import { useDebounceFn } from 'ahooks';
import { useRef, useState } from 'react';
import { RelocateTrailTimeTypeMap, timeTypeOptions } from './constants';
import dayjs from 'dayjs';
import styles from './style.module.less';

const rangeStart = '2019';
const rangeEnd = `${new Date().getFullYear()}`;
interface DateTimePickerProps {
  value?: [string, string];
  onChange?: (value: [string?, string?]) => void;
}
const DateTimePicker = (props: DateTimePickerProps) => {
  const [selectorValue, dateValue] = props.value || [];
  const selectorValueRef = useRef('');
  const dateValueRef = useRef('');
  const { run } = useDebounceFn(
    () => {
      props.onChange!([selectorValueRef.current, dateValueRef.current]);
    },
    {
      wait: 500,
    }
  );

  return (
    <JLLDatePicker.JLLQuarterPicker
      isRange
      allowClear={false}
      showDateTypeList={[]}
      selectorValue={selectorValue}
      className={styles.transactionTimeRangePicker}
      minDate={dayjs(rangeStart)}
      maxDate={dayjs(rangeEnd)}
      customOptions={timeTypeOptions}
      value={dateValue}
      onChange={(changeDateValue) => {
        dateValueRef.current = changeDateValue as string;
        run();
      }}
      onSelectorChange={(changeSelectorValue) => {
        selectorValueRef.current = changeSelectorValue;
        run();
      }}
    />
  );
};

export default DateTimePicker;
