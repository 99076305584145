import { LeaseTypeOptions, commercialAndIndustrialOptions } from '@/constants/options';
import useReactShareSelectedStore, { useCity } from '@/store/reactShareSelectedStore';
import useUserEvent from '@/hooks/useUserEvent';
import {
  Button,
  Drawer,
  JLLCascader,
  JLLDatePicker,
  JLLForm,
  JLLSvg,
  Radio,
  JLLCityPicker,
} from 'jlld';
import { useForm } from 'jlld/es/form/Form';
import { useEffect, useRef, useState, useMemo } from 'react';
import useIndustrialStore from '../../store';
import styles from './style.module.less';
import { CheckEnterpriseSources, IndustrialPicker, LeaseUserTypePicker } from './commonFormItems';
import useCommonOptionsStore, { queryDistrictSubmarkets } from '@/store/commonOptions';
import { PopupFormValues, TopFormValues } from '../../types';
import { LeaseType, PropertyType } from '@/constants/enums';
import { OptionsToDefaultValues } from 'jlld/es/JLLCascader';
import useReactShareUserStore from '@/store/reactShareUserStore';
import { useShallow } from 'zustand/react/shallow';
import { queryIndustryPageOptions } from '@/store/commonOptions/pageActions';
import ProjectOrIndustryFormItem from '@/components/CommonFormItems/PropertyTypeFormItem';
import { deepClone } from '@/utils/tool';
import { sendFormChange } from '../../sendEvent';
import DistrictAndSubMarketItem from './DistrictAndSubMarket';
import { PickerType } from 'jlld/es/JLLDatePicker';

const commonFormItemKey = [
  'city',
  'enterpriseSources',
  'secondaryIndustries',
  'queryTime',
  'leaseType',
  'propertyType',
];

const TopForms = (props: { pageElement: React.RefObject<HTMLDivElement> }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [datePickerType, setDatePickerType] = useState<PickerType>('quarter');
  const [showUserTypePicker, setShowUserTypePicker] = useState(window.innerWidth > 1500);
  const originPopupFormValues = useRef({});
  const originPopupDateType = useRef<PickerType>(datePickerType);

  const city = useCity();
  const setSelectedCity = useReactShareSelectedStore((state) => state.setSelectedCity);

  const { mapMode, queryPageData, toggleMapMode, setQueryParam, setLoading } = useIndustrialStore(
    (state) => ({
      loading: state.loading,
      mapMode: state.mapMode,
      queryPageData: state.queryPageData,
      setQueryParam: state.setQueryParam,
      toggleMapMode: state.toggleMapMode,
      setLoading: state.setLoading,
    })
  );

  const { industryOptions, dateRange, cityPickerOptions, districts, submarkets } =
    useCommonOptionsStore(
      useShallow((state) => ({
        cityPickerOptions: state.cityPickerOptions,
        industryOptions: state.industryOptions,
        dateRange: state.dateRange,
        districts: state.districts,
        submarkets: state.submarkets,
      }))
    );

  const user = useReactShareUserStore.getState().user;

  const currCommercialAndIndustrialOptions = useMemo(() => {
    const result = [];

    if (user.propertyType.includes(PropertyType.commercial)) {
      result.push(commercialAndIndustrialOptions[0]);
    }
    if (user.propertyType.includes(PropertyType.industrial)) {
      result.push(commercialAndIndustrialOptions[1]);
    }
    return result;
  }, [commercialAndIndustrialOptions]);

  const { sendUserEvent } = useUserEvent();

  const [form] = useForm<TopFormValues>();
  const [popupForm] = useForm<PopupFormValues>();

  const toQueryData = () => {
    // form的values不需要merge，因为popup会同步
    const params = popupForm.getFieldsValue();
    queryPageData({ ...params });
  };

  const getFormDefaultValues = (): Partial<PopupFormValues> => {
    return {
      city,
      queryTime: dateRange ? dateRange![1] : '',
      secondaryIndustries: OptionsToDefaultValues(industryOptions, [0]) as Record<string, string[]>,
      leaseType: LeaseType.existing,
      propertyType: OptionsToDefaultValues(currCommercialAndIndustrialOptions, [0]) as Record<
        string,
        string[]
      >,
    };
  };

  const getPopupFormDeaultValues = (): Partial<PopupFormValues> => {
    const allSubMarketsValue = submarkets[0]?.children?.map((item) => item.value);
    return {
      ...getFormDefaultValues(),
      districtAndSubmarket: allSubMarketsValue?.length
        ? { submarket: allSubMarketsValue }
        : { district: districts[0]?.children?.map((item) => item.value) },
      propertyType: OptionsToDefaultValues(currCommercialAndIndustrialOptions, [0]) as Record<
        string,
        string[]
      >,
    };
  };

  const reloadDistrictAndSubMarket = async (cityName: string) => {
    const { submarkets } = await queryDistrictSubmarkets(cityName);
    popupForm.setFieldValue('districtAndSubmarket', {
      submarket: submarkets[0].children?.map((item) => item.value),
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setShowUserTypePicker(window.innerWidth > 1500);
    };

    // 监听窗口的resize事件
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    popupForm.setFieldValue('queryTime', dateRange![1]);
    form.setFieldValue('queryTime', dateRange![1]);
    // toQueryData();
  }, [dateRange]);

  // if (loading.topForm) {
  //   return (
  //     <div className={styles.topFormsSkeleton}>
  //       <div className={styles.topForm}>
  //         {Array.from({ length: 4 }, (_, index) => (
  //           <Skeleton.Input
  //             key={index}
  //             style={{ width: 216, marginRight: 16 }}
  //             active
  //           />
  //         ))}
  //         <Skeleton.Input
  //           style={{ width: 262, marginRight: 16 }}
  //           active
  //         />
  //         <Skeleton.Button style={{ width: 32, minWidth: 32 }} />
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="topFormsContainer">
        <JLLForm
          layout="inline"
          className="formInHeader"
          form={form}
          onLoad={() => {
            const params = popupForm.getFieldsValue();
            setQueryParam({ ...params });
            setLoading('topForm', false);
          }}
          initialValues={getFormDefaultValues()}
          onValuesChange={async (changeValues) => {
            popupForm.setFields(
              Object.entries(changeValues).map(([key, value]) => ({ name: key, value }))
            );
            if (changeValues.city) {
              sendUserEvent({ button: '城市切换' }, 'e_click');
              await queryIndustryPageOptions(changeValues.city);
              await reloadDistrictAndSubMarket(changeValues.city);
              setSelectedCity(changeValues.city);
            }
            if (changeValues.secondaryIndustries) {
              sendUserEvent({ button: '行业切换' }, 'e_click');
            }
            if (changeValues.propertyType) {
              sendUserEvent({ button: '物业类型切换' }, 'e_click');
            }
            if (changeValues.queryTime) {
              sendUserEvent({ button: '时间切换' }, 'e_click');
            }
            if (mapMode === 'projectMarksMode') {
              toggleMapMode('districtAndMarketMode');
            }
            toQueryData();
          }}
        >
          <JLLForm.Item
            noStyle
            name="city"
          >
            <JLLCityPicker
              mode="single"
              className="headerFormItem"
              options={cityPickerOptions!}
            />
          </JLLForm.Item>
          <IndustrialPicker />
          <JLLForm.Item
            noStyle
            name="queryTime"
          >
            <JLLDatePicker.JLLQuarterPicker
              onTypeChange={(type) => {
                setDatePickerType(type);
              }}
              allowClear={false}
              type={datePickerType}
              minMaxDate={dateRange!}
              className="headerFormItem"
            />
          </JLLForm.Item>
          <ProjectOrIndustryFormItem className="headerFormItem" />
          {showUserTypePicker ? <LeaseUserTypePicker /> : <></>}
          <div
            className={styles.switchFormType}
            onClick={() => {
              sendUserEvent({ button: '产业分析筛选' }, 'e_click');
              originPopupDateType.current = datePickerType;
              originPopupFormValues.current = deepClone(popupForm.getFieldsValue());
              setShowPopup(true);
            }}
          >
            <JLLSvg
              icon="filter"
              size={18}
            />
          </div>
        </JLLForm>
      </div>
      <Drawer
        forceRender
        closable
        title="条件筛选"
        placement="top"
        height="auto"
        open={showPopup}
        footer={
          <>
            <Button
              type="text"
              onClick={() => {
                sendUserEvent({ button: '重置', module: '条件搜索' }, 'e_click');
                form.resetFields();
                popupForm.resetFields();
                form.setFieldsValue(getFormDefaultValues());
                popupForm.setFieldsValue(getPopupFormDeaultValues());
                toQueryData();
                setShowPopup(false);
              }}
            >
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const syncData = commonFormItemKey.reduce((commonFormData, key) => {
                  if (popupForm.getFieldsValue([key])) {
                    commonFormData[key] = popupForm.getFieldValue(key);
                  }
                  return commonFormData;
                }, {} as Record<string, any>);
                form.setFieldsValue(syncData);
                setShowPopup(false);

                if (syncData.city) {
                  setSelectedCity(syncData.city);
                }
                sendUserEvent({ button: '搜索', module: '条件搜索' }, 'e_click');

                toQueryData();
              }}
            >
              确定
            </Button>
          </>
        }
        onClose={() => {
          setShowPopup(false);
          setDatePickerType(originPopupDateType.current);
          popupForm.resetFields();
          popupForm.setFieldsValue(originPopupFormValues.current);
        }}
        getContainer={props.pageElement.current || false}
      >
        <JLLForm
          layout="horizontal"
          className="popupFormContainer"
          initialValues={getPopupFormDeaultValues()}
          form={popupForm}
          onValuesChange={async (changeValues, all) => {
            if (changeValues.city) {
              await reloadDistrictAndSubMarket(changeValues.city);
            }
            sendFormChange(changeValues, sendUserEvent);
          }}
        >
          <div className="selectFormContainer">
            <JLLForm.Item
              noStyle
              name="city"
            >
              <JLLCityPicker
                mode="single"
                className="headerFormItem"
                options={cityPickerOptions!}
              />
            </JLLForm.Item>
            <IndustrialPicker />
            <JLLForm.Item
              noStyle
              name="queryTime"
            >
              <JLLDatePicker.JLLQuarterPicker
                onTypeChange={(type) => {
                  setDatePickerType(type);
                }}
                type={datePickerType}
                minMaxDate={dateRange!}
                className="headerFormItem"
              />
            </JLLForm.Item>
            <DistrictAndSubMarketItem />
            <JLLForm.Item
              noStyle
              name="propertyType"
            >
              <JLLCascader
                className="headerFormItem"
                placeholder="全部物业类型"
                options={currCommercialAndIndustrialOptions}
              />
            </JLLForm.Item>
          </div>
          <div className="rowFormContainer">
            <div className="rowFormItem">
              <div className="rowFormItemLabel">租户范围</div>
              <JLLForm.Item
                name="leaseType"
                noStyle
              >
                <Radio.Group options={LeaseTypeOptions} />
              </JLLForm.Item>
            </div>
            <div className="rowFormItem">
              <div className="rowFormItemLabel">租户来源</div>
              <JLLForm.Item
                noStyle
                name="enterpriseSources"
              >
                <CheckEnterpriseSources />
              </JLLForm.Item>
            </div>
          </div>
        </JLLForm>
      </Drawer>
    </>
  );
};

export default TopForms;
