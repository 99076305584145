import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useRelocateTrailStore from '../../../store';
import { RelocateTrailMapProperty } from '../../../../../types/apiTypes';
import PropertyInfoCard from '../PropertyInfoCard';
import { Marker } from '@uiw/react-amap-marker';
import AutoFitInfoWindow from 'jlld/es/JLLMap/AutoFitInfoWindow';
import styles from './style.module.less';

export default () => {
  const trailMapData = useRelocateTrailStore((state) => state.trailMapData);
  const dimension = useRelocateTrailStore((state) => state.dimension);

  const [activeItem, setActiveItem] = useState<RelocateTrailMapProperty>(
    {} as RelocateTrailMapProperty
  );

  const [infoCardVisiable, setInfoCardVisiable] = useState(false);
  const [hoverPropertyId, setHoverPropertyId] = useState('');

  useEffect(() => {
    // TODO: 在切换事件隐藏
    setInfoCardVisiable(false);
  }, [dimension]);

  const clickMark = (item: RelocateTrailMapProperty) => {
    setActiveItem(item);
    setInfoCardVisiable(true);
  };

  const onInfoCardClose = () => {
    setActiveItem({} as RelocateTrailMapProperty);
    setInfoCardVisiable(false);
  };
  const renderPropertyMakers = () => {
    return (
      <>
        {trailMapData?.otherProperty?.map((item, idx) => {
          return (
            <Marker
              key={idx}
              position={item?.center}
              anchor="center"
              onMouseOver={() => {
                setHoverPropertyId(item.propertyId);
              }}
              onMouseOut={() => {
                setHoverPropertyId('');
              }}
              onClick={() => clickMark(item)}
              visiable
              zIndex={1000}
            >
              <div className={styles.propertyMarkerWrap}>
                <div className={classNames(styles.propertyMarker, styles.otherProperty)}>
                  {item.tenantNum}
                </div>
                <div
                  className={classNames(styles.markName, {
                    [styles.active]: item.propertyId === hoverPropertyId,
                  })}
                >
                  {item.propertyName}
                </div>
              </div>
            </Marker>
          );
        })}

        <Marker
          position={trailMapData?.curProperty?.center}
          anchor="center"
          onClick={() => clickMark(trailMapData?.curProperty)}
          visiable={true}
          onMouseOver={() => {
            setHoverPropertyId(trailMapData?.curProperty?.propertyId);
          }}
          onMouseOut={() => {
            setHoverPropertyId('');
          }}
          zIndex={1101}
        >
          <div className={styles.propertyMarkerWrap}>
            <div className={classNames(styles.propertyMarker, styles.currentProperty)}>
              {trailMapData?.curProperty?.propertyId === hoverPropertyId ? (
                <img src="/assets/map/relocate/property_current_hover.png" />
              ) : (
                <img src="/assets/map/relocate/property_current_default.png" />
              )}
            </div>
            {trailMapData?.curProperty?.propertyId === hoverPropertyId ? (
              <div className={classNames(styles.markName, styles.active)}>
                {trailMapData?.curProperty?.propertyName}
              </div>
            ) : null}
          </div>
        </Marker>
      </>
    );
  };

  return (
    <>
      <AutoFitInfoWindow
        position={activeItem.center}
        arrowStyle={{ backgroundColor: '#fff' }}
        visiable={infoCardVisiable}
      >
        <PropertyInfoCard
          data={activeItem}
          currentPropertyId={trailMapData?.curProperty?.propertyId}
          onClose={onInfoCardClose}
        />
      </AutoFitInfoWindow>

      {renderPropertyMakers()}
    </>
  );
};
