import { useEffect } from 'react';
import DataTable from './components/DataTable';
import MapView from './components/MapView';
import TopFilter from './components/TopFilter';
import useRelocateTrailStore, {
  queryRelocateTrailListData,
  queryRelocateTrailMapData,
  setDisplayMode,
  resetRelocateData,
} from './store';
import useMainFrameContext from '@/hooks/useMainFrameContext';
import { RelocateTrailDimensionType, RelocateTrailModeType } from '../../types';
import { getLastQuarter, getNextQuarter } from '@/utils/tool';
import { PropertyType } from '@/constants/enums';

import styles from './style.module.less';

export default () => {
  const { data: mainFrameData } = useMainFrameContext();
  const { id: projectId, type } = mainFrameData.params;

  const displayMode = useRelocateTrailStore((state) => state.displayMode);

  useEffect(() => {
    // TODO: 需要统一名字, 然后直接PropertyType[type]即可
    const propertyType = type === 'business' ? PropertyType.commercial : PropertyType.industrial;
    const [year, quarter] = getLastQuarter()
      .split('-Q')
      .map((item) => Number(item));

    const params = {
      propertyId: projectId,
      propertyType,
      dimension: RelocateTrailDimensionType.moveIn,
      startTime: getNextQuarter(`${year - 3}-Q${quarter}`),
      endTime: getLastQuarter(),
    };

    if (displayMode === RelocateTrailModeType.map) {
      queryRelocateTrailMapData(params);
    } else {
      queryRelocateTrailListData(params);
    }

    return () => {
      resetRelocateData();
    };
  }, []);

  return (
    <div className={styles.relocateTrailPage}>
      <TopFilter />
      {displayMode === RelocateTrailModeType.map ? <MapView /> : <DataTable />}
    </div>
  );
};
